import { FilterFormSubmitButton, FilterFormTexts } from '../model/form-model';

export const defaultLabels: FilterFormTexts = {
  action: 'Mám záujem o',
  subcategory: 'Typ nehnuteľnosti',
  city: 'Lokalita',
  priceRange: 'Cena',
  condition: 'Stav nehnuteľnosti',
  keywords: 'Kľúčové slová',
  sortBy: 'Zoradiť podľa',
  areaRange: 'Rozloha',
};

export const defaultPlaceholders: FilterFormTexts = {
  action: 'Všetky možnosti',
  subcategory: 'Všetky možnosti',
  city: 'Všetky možnosti',
  condition: 'Všetky možnosti',
  keywords: 'garáž, balkón...',
  sortBy: 'Všetky možnosti',
};

export const defaultSubmitButton: FilterFormSubmitButton = {
  text: 'Hľadať nehnuteľnosť',
  loadingText: 'Hľadám nehnuteľnosť',
  colorScheme: 'black',
};

export const ACTIVE_PROPERTY_STATUS_FILTER = {
  or: [{ realsoftId: { eq: 242 } }, { realsoftId: { eq: 30822 } }],
};
export const REALIZED_PROPERTY_STATUS_FILTER = {
  or: [{ realsoftId: { eq: 241 } }, { realsoftId: { eq: 30824 } }],
};

export const ACTIVE_PROPERTY_STATUS_SLUG = 'active';
export const REALIZED_PROPERTY_STATUS_SLUG = 'realized';
